export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Polaris',
  hostName: 'https://polarisus-analytics-staging.azurewebsites.net',
  environmentName: 'staging',
  appName: 'Polaris Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: 'fab9744f-7521-4b2c-9f92-5573b1e5b60d'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#1b365c', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://polarisus-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://polarisus-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://polarisus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/polaris/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: true,
  harmonyUrl: '',
  v5Reports: ['WebsiteOverview']
};
