import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
        'service-scheduler-provider': { displayName: 'Provider', name: 'service-scheduler-provider' },
        'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'brand': { displayName: 'Brand', name: 'brand' },
        'dealer-size': { displayName: 'Dealer Size', name: 'dealer-size' },
        'role': { displayName: 'Role', name: 'role' },
        'page': { displayName: 'Page', name: 'page' },
        'sales-unit': { displayName: 'Sales Unit', name: 'sales-unit' },
        'product-line': { displayName: 'Product Line', name: 'product-line' },
        'campaign-type': { displayName: 'Campaign Type', name: 'campaign-type'}
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
      // Leads
      'LeadsOverview': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        restrictDates: true,
        reportName: 'LeadsOverview',
        filters: ['org', 'sales-unit', 'product-line'],
        defaults: ['org', 'sales-unit'],
        section: 'leads',
        orgTypes: [1, 2, 3]
      },
      'LeadSummary': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'LeadSummary',
        filters: [
          'org', 'lead-type', 'source-type', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'lead-type', 'source-type', 'sales-unit'
        ],
        section: 'leads',
        orgTypes: [1, 2, 3]
      },
      'LeadSourcePerformance': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'LeadSourcePerformance',
        filters: [
          'org', 'lead-type', 'source-type', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'lead-type', 'source-type', 'sales-unit'
        ],
        section: 'leads',
        orgTypes: [1, 2, 3]
      },
      'LeadVehiclePerformance': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'LeadVehiclePerformance',
        filters: [
          'org', 'lead-type', 'source-type', 'vehicle-model', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'lead-type', 'source-type', 'sales-unit'
        ],
        section: 'leads',
        orgTypes: [1, 2, 3]
      },
      'LeadTransactions': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'LeadTransactions',
        filters: [
          'org', 'lead-type', 'source-type', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'lead-type', 'source-type', 'sales-unit'
        ],
        section: 'leads',
        orgTypes: [1, 2, 3]
      },
      'ProcessPerformance': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'ProcessPerformance',
        filters: [
          'org', 'lead-type', 'source-type', 'vehicle-model', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'lead-type', 'source-type', 'sales-unit'
        ],
        section: 'leads',
        orgTypes: [1, 2, 3]
      },
      // Sales
      'SalesOverview': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        restrictDates: true,
        reportName: 'SalesOverview',
        filters: ['org', 'sales-unit', 'product-line'],
        defaults: ['org', 'sales-unit'],
        section: 'sales',
        orgTypes: [1, 2, 3]
      },
      'SalesSummary': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'SalesSummary',
        filters: [
          'org', 'sale-type', 'lead-type', 'source-type', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'sale-type', 'lead-type', 'source-type', 'sales-unit'
        ],
        section: 'sales',
        orgTypes: [1, 2, 3]
      },
      'SalesVehicleSummary': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'SalesVehicleSummary',
        filters: [
          'org', 'sale-type', 'lead-type', 'source-type', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'lead-type', 'sale-type', 'source-type', 'sales-unit'
        ],
        section: 'sales',
        orgTypes: [1, 2, 3]
      },
      'SalesSourceSummary': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        restrictDates: false,
        reportName: 'SalesSourceSummary',
        filters: [
          'org', 'sale-type', 'lead-type', 'source-type', 'sales-unit', 'product-line'
        ],
        defaults: [
          'org', 'sale-type', 'lead-type', 'sales-unit'
        ],
        section: 'sales',
        orgTypes: [1, 2, 3]
      },
      // Website
      'WebsiteOverview': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'WebsiteOverview',
        filters: ['org', 'channel', 'website-provider', 'device-type', 'sales-unit', 'product-line'],
        defaults: ['org', 'sales-unit'],
        section: 'website',
        orgTypes: [1, 2, 3]
      },
      //'WebsiteScorecard': {
      //  dateMode: Enums.dateModes.currentMonth,
      //  restrictDates: true,
      //  reportName: 'WebsiteScorecard',
      //  filters: [
      //    'org', 'website-provider'
      //  ],
      //  defaults: [
      //    'org'
      //  ],
      //  section: 'website',
      //  orgTypes: [1, 2, 3]
      //},
      //'WebsiteSummary': {
      //  dateMode: Enums.dateModes.currentMonth,
      //  reportName: 'WebsiteSummary',
      //  filters: [
      //    'org', 'website-provider'
      //  ],
      //  defaults: [
      //    'org'
      //  ],
      //  section: 'website',
      //  orgTypes: [1, 2, 3]
      //},
      //'WebsiteReferrerSummary': {
      //  dateMode: Enums.dateModes.currentMonth,
      //  reportName: 'WebsiteReferrerSummary',
      //  filters: [
      //    'org', 'device-type'
      //  ],
      //  defaults: [
      //    'org', 'device-type'
      //  ],
      //  section: 'website',
      //  orgTypes: [1, 2, 3]
      //},
      //'WebsiteVehicleSummary': {
      //  dateMode: Enums.dateModes.currentMonth,
      //  reportName: 'WebsiteVehicleSummary',
      //  filters: [
      //    'org', 'website-provider'
      //  ],
      //  defaults: [
      //    'org'
      //  ],
      //  section: 'website',
      //  orgTypes: [1, 2, 3]
      //},
      //'WebsiteProviderSummary': {
      //  dateMode: Enums.dateModes.currentMonth,
      //  reportName: 'WebsiteProviderSummary', // org only
      //  filters: ['org'],
      //  defaults: ['org'],
      //  section: 'website',
      //  orgTypes: [1, 2, 3]
      //},
      // DigAd
      'DigitalAdvertisingDashboard': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.custom,
        restrictDates: false,
        reportName: constants.reportNames.digitalAdvertisingDashboard,
        filters: ['org', 'channel', 'digad-provider', 'sales-unit', 'product-line'],
        defaults: ['org', 'sales-unit'],
        section: 'digital-advertising',
        orgTypes: [1, 2, 3]
      },
      // Digital Retailing
      'DigitalRetailingOverview': {
        salesCalendarToggleDisabled: true,
        reportName: 'Digital Retailing Overview',
        dateMode: Enums.dateModes.currentMonth,
        filters: ['org', 'digital-retailing-provider', 'device-type'],
        defaults: ['org'],
        section: 'provider-tools',
        orgTypes: [1, 2, 3]
      },
      //   Chat
      'ChatOverview': {
        salesCalendarToggleDisabled: true,
        dateMode: Enums.dateModes.currentMonth,
        reportName: constants.reportNames.chatOverview,
        filters: ['org', 'device-type', 'chat-provider'],
        defaults: ['org'],
        section: 'chat',
        orgTypes: [1, 2, 3]
      },
      // Trade-In
      'TradeInOverview': {
        salesCalendarToggleDisabled: true,
        reportName: constants.reportNames.tradeInOverview,
        dateMode: Enums.dateModes.currentMonth,
        filters: ['org', 'device-type', 'trade-in-provider'],
        defaults: ['org'],
        section: 'provider-tools',
        orgTypes: [1, 2, 3]
      },
      // Service Scheduler
      'ServiceSchedulerOverview': {
        salesCalendarToggleDisabled: true,
        reportName: 'Service Scheduler Overview',
        dateMode: Enums.dateModes.currentMonth,
        filters: ['org', 'device-type', 'service-scheduler-provider'],
        defaults: ['org'],
        section: 'provider-tools',
        orgTypes: [1, 2, 3]
      },
      // User Activity
      'UserActivityOverview': {
        salesCalendarToggleDisabled: true,
        reportName: 'UserActivityOverview',
        dateMode: Enums.dateModes.currentMonth,
        filters: ['role', 'page'],
        defaults: ['role', 'page'],
        section: 'user-activity',
        orgTypes: [1, 2, 3]
      }
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [],
            selected: [
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Sales' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [{ value: 1, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            selected: [
                { value: 1, display: 'Brand' },
                { value: 3, display: 'Dealer Website' },
                { value: 5, display: 'Event' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [
              { value: 1, display: 'Chrysler' },
              { value: 2, display: 'Dodge' },
              { value: 3, display: 'Jeep' },
              { value: 4, display: 'Ram' },
              { value: 5, display: 'Fiat' },
              { value: 6, display: 'Alfa Romeo' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [2,3,4,5,6,7,8],
            selected: [
              { value: 2, display: 'ARI' },
              { value: 3, display: 'Dealer Spike' },
              { value: 4, display: 'DX1' },
              { value: 5, display: 'Pixel Motion' },
              { value: 6, display: 'PSX Digital' },
              { value: 7, display: 'SM360' },
              { value: 8, display: 'PSM' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            selected: [
                { value: 1, display: 'Brand' },
                { value: 2, display: 'Paid Search' },
                { value: 3, display: 'Organic Search' },
                { value: 4, display: 'Paid Display' },
                { value: 5, display: 'Paid Video' },
                { value: 6, display: 'Paid Email' },
                { value: 7, display: 'Organic Email' },
                { value: 8, display: 'Paid Social' },
                { value: 9, display: 'Organic Social' },
                { value: 10, display: 'Third Party Sites' },
                { value: 11, display: 'Typed/Bookmarked' },
                { value: 12, display: 'Other' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
      'digad-provider': {
        type: 'digad-provider',
        available: [1, 9],
        selected: [
          { value: 1, display: 'Shift Digital' },
          { value: 2, display: 'Ovative' },
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'digital-retailing-provider': {
        type: 'digital-retailing-provider',
        available: [],
        selected: [
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'trade-in-provider': {
        type: 'trade-in-provider',
        available: [],
        selected: [
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'service-scheduler-provider': {
        type: 'service-scheduler-provider',
        available: [],
        selected: [
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'brand': {
        type: 'brand',
        selected: [
          { value: 1, display: 'Chrysler' },
          { value: 2, display: 'Dodge' },
          { value: 3, display: 'Jeep' },
          { value: 4, display: 'Ram' },
          { value: 5, display: 'Fiat' },
          { value: 6, display: 'Alfa Romeo' },
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'dealer-size': {
        type: 'dealer-size',
        selected: [],
        removable: true,
        lockable: true,
        locked: false
      },
      'role': {
        type: 'role',
        available: [2, 6],
        selected: [
          { value: 2, display: 'Corporate' },
          { value: 6, display: 'Dealer' },
        ],
        removable: false,
        lockable: true,
        locked: false
      },
      'sales-unit': {
        type: 'sales-unit',
        available: [1, 2, 3, 4],
        selected: [
          { value: 1, display: 'ORV & SNO' },
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'product-line': {
        type: 'product-line',
        available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        selected: [
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'page': {
        type: 'page',
        available: [13, 14, 15, 16, 18, 19, 20, 21, 24, 25, 32, 33, 34, 35, 49],
        selected: [
          //{ value: 1, display: 'Digital Retailing Overview' },
          //{ value: 2, display: 'Digital Retailing Overview' },
          //{ value: 3, display: 'Digital Retailing In-Store' },
          //{ value: 4, display: 'Trade-In Overview' },
          //{ value: 5, display: 'Trade-In Provider Scorecard' },
          //{ value: 6, display: 'Service Scheduler' },
          //{ value: 7, display: 'Chat Overview' },
          //{ value: 8, display: 'Chat Provider Scorecard' },
          //{ value: 9, display: 'Social Media Overview' },
          //{ value: 10, display: 'Reputation Management Overview' },
          //{ value: 11, display: 'Digital Advertising Overview' },
          //{ value: 12, display: 'Digital Advertising Dashboard' },
          { value: 13, display: 'Digital Advertising Scorecard' },
          { value: 14, display: 'Digital Advertising Summary' },
          { value: 15, display: 'Digital Advertising Channel Performance' },
          { value: 16, display: 'Digital Advertising Provider Performance' },
          //{ value: 17, display: 'Digital Advertising Provider Scorecard' },
          { value: 18, display: 'Leads Overview' },
          { value: 19, display: 'Leads Summary' },
          { value: 20, display: 'Leads Source Performance' },
          { value: 21, display: 'Leads Vehicle Performance' },
          //{ value: 22, display: 'Leads Event Performance' },
          //{ value: 23, display: 'Leads Facebook Performance' },
          { value: 24, display: 'Leads Process Performance' },
          { value: 25, display: 'Leads Transaction' },
          //{ value: 26, display: 'Inventory Overview' },
          //{ value: 27, display: 'Marketing Best Practices' },
          //{ value: 28, display: 'Lead Best Practices' },
          //{ value: 29, display: 'Dealer Best Practices' },
          //{ value: 30, display: 'Dealer Website Best Practices' },
          //{ value: 31, display: 'Dealer Resources' },
          { value: 32, display: 'Resources' },
          { value: 33, display: 'Sales Overview' },
          { value: 34, display: 'Sales Summary' },
          { value: 35, display: 'Sales Vehicle' },
          //{ value: 36, display: 'Sales Source' },
          //{ value: 37, display: 'Sales Forecasting' },
          //{ value: 38, display: 'Website Overview' },
          //{ value: 39, display: 'Website Summary' },
          //{ value: 40, display: 'Website Scorecard' },
          //{ value: 41, display: 'Website Referrer Performance' },
          //{ value: 42, display: 'Website Vehicle Performance' },
          //{ value: 43, display: 'Website Landing Page' },
          //{ value: 44, display: 'Website After Sales' },
          //{ value: 45, display: 'Website Provider Performance' },
          //{ value: 46, display: 'Website Provider Scorecard' },
          //{ value: 47, display: 'Website Overview' },
          //{ value: 48, display: 'Website Summary' },
          { value: 49, display: 'User Activity' }
        ],
        removable: false,
        lockable: false,
        locked: false
      },
    };
}
