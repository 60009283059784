import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

  orgLookupTypes: Record<string, IOrgLookupType> = {
    'orv': { name: 'ORV & SNO', orgLookupTypeId: 1, default: true, orgEntityType: 'national' },
    'onroad': { name: 'On-Road', orgLookupTypeId: 2, default: false, orgEntityType: 'national' },
    'commercial': { name: 'Commercial', orgLookupTypeId: 3, default: false, orgEntityType: 'national' }
  };

  public nationalOrgLookupTypeIds: number[] = [1,2,3];
  public lmaOrgLookupTypeIds: number[] = [];
  public virtual20OrgLookupTypeIds: number[] = [];
  public dealerGroupOrgLookupTypeIds: number[] = [];

  orgEntityType: Map<string, string> = new Map([
    ['orgcode2', 'orgcode2'],
    ['orgcode1', 'orgcode1'],
    ['dealer', 'dealer'],
  ]);

  entityTypeName: Map<string, string> = new Map([
    ['orgcode2', 'BC'],
    ['orgcode1', 'District'],
    ['dealer', 'Dealer'],
  ]);
}
