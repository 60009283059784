import { ILeadTransactionsConfig, IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadTransactionsConfig implements ILeadTransactionsConfig {

    public maskSameDealerSales: boolean = false;
    public useSourceMask?: boolean = true;
    public excludeFromLeadDetailsTemplate: string[] = ['customerComments'];

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        // Lead Date
        {
            show: true,
            header: 'Lead Date',
            columnDef: 'created',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        // Lead Id
        //{
        //    show: true,
        //    header: 'Lead ID',
        //    columnDef: 'leadId',
        //    isMom: false,
        //    isYoy: false,
        //    metricFormatKey: constants.formatKeys.entityDisplayName,
        //    clickable: false
        //},
        //// Lead Key
        //{
        //    show: true,
        //    header: 'Lead Key',
        //    columnDef: 'leadKey',
        //    isMom: false,
        //    isYoy: false,
        //    metricFormatKey: constants.formatKeys.entityDisplayName,
        //    clickable: false
        //},
        // Dealer Code
        {
            show: true,
            header: 'Dealer Code',
            columnDef: 'dealerCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        //// Dealer Name
        //{
        //    show: true,
        //    header: 'Dealer Name',
        //    columnDef: 'dealerName',
        //    isMom: false,
        //    isYoy: false,
        //    metricFormatKey: constants.formatKeys.entityDisplayName,
        //    clickable: false
        //},
        // Lead Source
        {
            show: true,
            header: 'Lead Source',
            columnDef: 'maskedSource',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Customer Name
        {
            show: true,
            header: 'Customer Name',
            columnDef: 'customerName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        // City
        {
            show: true,
            header: 'City',
            columnDef: 'city',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // State
        {
            show: true,
            header: 'State',
            columnDef: 'state',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Model
        {
            show: true,
            header: 'Model',
            columnDef: 'vehicleModel',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Live Date
        {
            show: true,
            header: 'Live Date',
            columnDef: 'liveDate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        // Responded
        {
            show: true,
            header: 'Responded',
            columnDef: 'responded',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        // Time to Respond
        {
            show: true,
            header: 'Time to Respond',
            columnDef: 'timeToRespond',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.hoursTimeStringFromMinutes,
            printFormatKey: constants.formatKeys.localeString,
            clickable: false
        },
        // Alternate Dealers
        {
            show: true,
            header: 'Alternate Dealers',
            columnDef: 'competingDealers',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString,
            clickable: false
        },
        // Sale Date
        {
            show: true,
            header: 'Sale Date',
            columnDef: 'saleDate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.date,
            clickable: false
        },
        // Sale Dealer (S, O, Blank)
        {
            show: true,
            header: 'Sale Dealer',
            columnDef: 'saleDealerCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Sale Dealer Name
        {
            show: true,
            header: 'Sale Dealer Name',
            columnDef: 'saleDealerName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Sale Zip Code
        {
            show: true,
            header: 'Sale Zip Code',
            columnDef: 'saleZipCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Sale Model
        {
            show: true,
            header: 'Sale Model',
            columnDef: 'saleModel',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Sale VIN
        //{
        //    show: true,
        //    header: 'Sale VIN',
        //    columnDef: 'saleVin',
        //    isMom: false,
        //    isYoy: false,
        //    metricFormatKey: constants.formatKeys.entityDisplayName,
        //    clickable: false
        //},
    ];

}
